<template>
  <div>
    <b-loading :is-full-page="true" :active="isLoading" :can-cancel="false" />
    <Page v-if="!isLoading" :page="page"  />
  </div>
</template>
<script>
import Page from '@/components/pages/Page';
import Api from '@/core/Api';

import { ToastProgrammatic as Toast } from 'buefy';
import deepClone from '@/core/utils/deepClone';

export default {
  components: {
    Page,
  },
  data () {
    this.loadPage();
    return {
      isLoading: true,
      page: {},
    };
  },
  methods: {
    async loadPage () {
      try {
        const payload = await Api.get(`/system_pages/${this.$route.params.id}`);
        if (payload.data && payload.data.success) {
          this.page = payload.data.documents[0];
        } else {
          this.page = {};
        }
        this.isLoading = false;

      } catch (e) {
        console.error(e);
        if (e.response && e.response.status) {
          this.errorStatus = e.response.status;
          this.$store.dispatch('errors/requestError', { message: 'Unauthorized', error: e, status: e.response.status });
        }
        this.isLoading = false;
      }
    },
    async savePage () {
      await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'system_pages',
        object: this.page,
      });
      Toast.open({ message: 'Document sauvegardé', type: 'is-success' });
    }

  }
};
</script>
<style>
</style>
