<template>
  <node-view-wrapper :class="`image-extension-component placement-${node.attrs.placement}`">
    <b-dropdown class="menu-dropdown" position="is-bottom-left">
      <template #trigger="{ active }">
        <a class="button">
          <i class="mdi mdi-dots-vertical" />
        </a>
      </template>
      <b-dropdown-item aria-role="listitem" @click="updateAttributes({ placement: 'full-line' })">
        Full line
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="updateAttributes({ placement: 'left' })">
        Left
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="updateAttributes({ placement: 'right' })">
        Right
      </b-dropdown-item>
    </b-dropdown>
    <div class="file-preview">
      <img v-if="imageLoaded" :src="imageSrc" />
      <a v-else-if="!formModel.file" @click="modalOpened = true">
        <i class="mdi mdi-upload" />
        Uploader ou sélectionner un fichier
      </a>
    </div>
    <Modal :active="modalOpened" @close="modalOpened = false">
      <Form :schema="schema" :model="formModel" @model-updated="updateModel">
        Ajouter un fichier
      </Form>
    </Modal>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';
import Modal from '@/components/modals/Modal';
import Form from '@/components/Form';
import axios from 'axios';

export default {
  components: {
    NodeViewWrapper,
    Modal,
    Form,
  },

  props: nodeViewProps,
  tiptapProps: {
    file: {
      default: '',
    },
    placement: {
      default: 'full-line',
    },
  },
  data () {
    this.$nextTick(() => {
      try {
        this.openFile(this.formModel.file);
      } catch (e) {
        console.error(e);
      }
    });

    return {
      imageSrc: undefined,
      imageLoaded: false,
      schema: {
        fields: [{
          type: 'document',
          relation: {
            collection: 'system_files',
            type: 'hasOne',
          },
          onlyFields: [
            'originalname'
          ],
          search: true,
          labelField: 'originalname',
          schemaName: 'file',
          model: 'file'
        }, {
          type: 'files',
          search: true,
          model: 'fileUpload'
        }]
      },
      modalOpened: false,
    };
  },
  computed: {
    formModel: {
      get() {
        if(this.node.attrs.file) {
          try {
            return {file: JSON.parse(this.node.attrs.file) };
          } catch (e) {}
        }
        return {};
      },
    },
  },
  methods: {
    updateModel () {
      setTimeout(() => {
        let file;
        if (this.formModel.fileUpload && this.formModel.fileUpload.length) {
          file = this.formModel.fileUpload[0];
        } else {
          file = this.formModel.file;
        }
        console.log('updatemodel file', this.formModel, file);
        this.updateAttributes({
          file: JSON.stringify(file)
        });
        this.modalOpened = false;
      }, 100);
    },
    openFile (file) {
      const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:3332';
      axios({
        url: `${baseUrl}/system_files/${file._id}/file`,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Token ${this.$store.state.login.user.token}`,
        },
      }).then((response) => {
        if (response.data && response.data.success === false) {
          this.fileDownloadError();
        }
        this.imageLoaded = true;
        this.imageSrc = URL.createObjectURL(new Blob([response.data], { type: 'image/jpg' }));
      }).catch((e) => {
        this.fileDownloadError();
      });
    },
  },
}
</script>

<style scoped>
.image-extension-component {
  position: relative;
}
.file-preview {
  color:  black;
}
.file-preview >>> a {
  display: block;
  color: #4a4a4a;
  padding-top: 3px;
  padding-bottom: 3px;
}
.file-preview:hover {
  background: whitesmoke;
}
.file-preview:hover >>> a {
  text-decoration: underline;
}
.menu-dropdown {
  position: absolute;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.image-extension-component:hover .menu-dropdown {
  opacity: 1;
}
.placement-left {
  float: left;
}
.placement-right {
  float: right;
}
</style>
