<template>
  <div class="menu">
    <ul class="menu-list">
      <li
        :class="{ 'is-selected': (index === selectedIndex && childrenItemsFor(scannedPath) === undefined) }"
        v-for="(item, index) in itemsToShow"
        :key="index"
      >
        <a
          :class="scannedPath === item ? 'hovered' : ''"
          @click="selectItemByIndex(index)"
          @mouseenter="typeof getItem(validQueryPart ? validQueryPart + '.' + item : item) === 'object' ? scannedPath = item : scannedPath = undefined"
        >
          <i :class="`mdi mdi-${iconForType(typeof getItem(validQueryPart ? validQueryPart + '.' + item : item))}`" />&nbsp;
          {{ item }}
          <span style="float: right" v-if="typeof getItem(validQueryPart ? validQueryPart + '.' + item : item) === 'object'">
            <i class="mdi mdi-chevron-right" />
          </span>
        </a>
      </li>
    </ul>
    <span class="sub-menu" v-if="scannedPath && (!query || query === '')">
      <PropertyList :ref="childPropertyList" :items="childrenItemsFor(scannedPath)" :command="command" :listRoot="listRoot ? listRoot + '.' + scannedPath : scannedPath" :scannedComponent="scannedComponent || $parent.$refs.PropertyListContext" :level="level + 1" :editor="editor" />
    </span>
  </div>
</template>
<script>
import scanComponentVars from '@/core/utils/scanComponentVars';
import getProp from '@/core/utils/getProp';

export default {
  name: 'PropertyList',
  props: {
    level: {
      type: Number,
      default: 0,
    },
    listRoot: {
      type: String,
      default: '',
    },
    scannedComponent: {
      type: Object,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => []
    },
    query: {
      type: String,
      default: ''
    },
    command: {
      type: Function,
      required: true,
    },
    editor: {
      type: Object,
      default: undefined,
    }
  },
  data() {
    return {
      scanComponentVars,
      selectedIndex: 0,
      scannedPath: undefined,
    }
  },
  computed: {
    scannedComponentWithSafety () {
      const res = this.scannedComponent || this.$parent.$refs.PropertyListContext;
      return res;
    },
    validQueryPart () {
      if(this.query && this.query.length) {
        let validQueryPart = this.query.split('.');
        validQueryPart.pop();
        validQueryPart = validQueryPart.join('.');
        return validQueryPart;
      }
    },
    itemsToShow () {
      if(this.validQueryPart) {
        return Object.keys(getProp(this.scannedComponentWithSafety, this.validQueryPart));
      } else {
        return this.items;
      }

    }
  },
  watch: {
    items() {
      this.selectedIndex = 0;
    },
  },

  methods: {
    getItem(item) {
      let itemPath = item;

      if (this.listRoot && this.listRoot !== '')  {
        itemPath = this.listRoot + '.' + itemPath;
      }
      return getProp(this.scannedComponentWithSafety, itemPath);
    },
    iconForType (type) {
      const types = {
        object: 'code-json',
        boolean: 'check',
        string: 'format-letter-case',
        number: 'numeric',
        undefined: 'square-off-outline',
        null: 'square-off-outline',
      };
      return types[type] || type;
    },
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length;
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },
    enterHandler() {
      let item = this.itemsToShow[this.selectedIndex];
      let type = typeof this.getItem(item);
      if (this.query && this.query[this.query.length - 1] === ')') {
        item = this.getItem(this.query);
        this.selectItemByPath(this.query);
      } else if (type === 'object') {
        this.editor.commands.insertContent(item.substr(this.query.length) + '.');
        this.scannedPath = this.items[this.selectedIndex];
      } else if (type === 'function') {
        this.editor.commands.insertContent(item.substr(this.query.length) + '(');
      } else {
        this.selectItemByIndex(this.selectedIndex);
      }
    },
    selectItemByPath(path) {
      this.command({ id: path });
    },
    selectItemByIndex(index) {
      const item = this.itemsToShow[index];
      const type = typeof this.getItem(item);

      if (item) {
        let res = item;
        if (type === 'function') {
          res = res + '()';
        }
        if (this.validQueryPart && this.validQueryPart !== '') {
          res = this.validQueryPart + '.' + item;
        }
        if (this.listRoot && this.listRoot !== '') {
          res = this.listRoot + '.' + item;
        }
        if (type === 'function') {
          console.log('F!', this.editor);
          this.editor.commands.insertContent(res.substr(this.query.length) + '(');
          this.editor.chain().focus()

        } else {
          console.log('selectItemByIndex res', type, res);
          this.command({ id: res });
        }
      }
    },
    childrenItemsFor(scannedPath) {
      if (scannedPath) {
        console.log('childrenItemsFor', scannedPath, this.level);
        return Object.keys(this.getItem(scannedPath));
      }
    }
  },
}
</script>
<style scoped>
  .menu {
    background: white;
    position:  relative;
    width: 210px;
    box-shadow: 0 0.5em 1em -0.125em hsl(0deg 0% 4% / 10%), 0 0 0 1px hsl(0deg 0% 4% / 2%);
  }
  .sub-menu {
    position: absolute;
    left: 200px;
    top: 0;
  }
  .hovered {
    background: var(--primary-color);
  }
  .is-selected {
    background: var(--primary-color);
    color: white;
  }
  ul {
    list-style: none;
    margin:  0;
  }
</style>
