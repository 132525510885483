<template>
  <node-view-wrapper class="chart">
    <b-loading :is-full-page="false" :active="!loaded" :can-cancel="false" />
    <a class="edit-button" v-if="!editLocked" @click="modalOpened = true">
      <i class="mdi mdi-cog mdi-48px" />
    </a>
    <div style="" v-if="loaded">
      <UIChart class="chart-component" :option="chartOptions"/>
    </div>
    <Modal class="edit-modal" :active="modalOpened" @close="modalOpened = false" >
      <template slot="header">
        Paramètres du widget
      </template>
      <div :style="isPortalActive ? 'height:100%': 'height: 0px'">
        <portal-target name="portalComplexField" @change="isPortalActive = $event" :style="isPortalActive ? 'height:100%': ''"/>
      </div>
      <b-tabs :class="`chart-options-tabs ${previewOpened ? 'preview-opened' : ''}`" vertical>
        <b-tab-item label="General">
          <vue-form-generator
            ref="form"
            :schema="widgetSchemaGeneral"
            :model="panelConfigVolatile"
            tag="div"
            :style="isPortalActive ? 'display: none': ''"
          />
        </b-tab-item>
        <b-tab-item label="Data">
          <vue-form-generator
            ref="form"
            :schema="widgetSchemaData"
            :model="panelConfigVolatile"
            tag="div"
            :style="isPortalActive ? 'display: none': ''"
          />
        </b-tab-item>
        <b-tab-item label="Axes / Légende">
        </b-tab-item>
        <b-tab-item label="Style">
        </b-tab-item>
      </b-tabs>
       <b-collapse class="card preview-card" @open="previewOpened = true" @close="previewOpened = false">
        <template #trigger="props">
          <div class="card-header" role="button">
            <a class="card-header-icon">
              <i :class="`mdi mdi-${props.open ? 'menu-down' : 'menu-right'}`" />
            </a>
            <p class="card-header-title">
              Aperçu
            </p>
          </div>
        </template>
        <div class="card-content">
          <div class="content" style="position: relative; height: 400px;">
            <UIChart class="chart-component" :option="chartOptions" />
          </div>
        </div>
      </b-collapse>
      <template slot="footer">
        <div class="modal-buttons">
          <a class="button save-button" @click="save">
            <i class="mdi mdi-close" />&nbsp;
            Annuler
          </a>
          <a class="button is-primary save-button" @click="save">
            <i class="mdi mdi-check" />&nbsp;
            Enregistrer
          </a>
        </div>
      </template>
    </Modal>
  </node-view-wrapper>
</template>
<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';
import Modal from '@/components/modals/Modal';
import deepClone from '@/core/utils/deepClone';
import Chart from '@/components/ui/Chart';

export default {
  components: {
    NodeViewWrapper,
    Modal,
    UIChart: Chart,
  },

  props: nodeViewProps,
  tiptapProps: {
    panelConfig: {
      default: '{}',
    },
  },

  data () {
    setTimeout(async () => {
      if (this.panelConfig) {
        await this.loadData();
      }
    }, 1000);
    return {
      previewOpened: true,
      isPortalActive: false,
      setupStep: 1,
      modalOpened: false,
      //setupDone: this.panelConfig && Object.keys(this.panelConfig),
      panelConfigVolatile: {},
      loaded: false,
      widgetSchemaGeneral: {
        fields: [{
          label: 'Type de visualisation',
          model: 'visualisation_type',
          type: 'select',
          values: [{
            id: 'one_document_per_serie',
            name: 'Un document par série, plusieurs clés spécifiées pour les valeurs'
          }, {
            id: 'multiple_document_per_serie',
            name: 'Plusieurs documents par série, une clé spécifiée pour la valeur'
          }]
        }, {
          type: 'text',
          model: 'name_key',
          label: 'Clé utilisée pour le nom de la série',
          visible: (model) => model.visualisation_type === 'one_document_per_serie',
        },{
          type: 'array',
          model: 'keys',
          label: 'Clés',
          itemContainerComponent: 'FieldArrayItemContainer',
          visible: (model) => model.visualisation_type === 'one_document_per_serie',
        }, {
          type: 'collection',
          model: 'collection',
          label: 'Collection',
          visible: (model) => model.visualisation_type === 'one_document_per_serie',
        }]
      },
      widgetSchemaData: {
        fields: [{
          type: 'array',
          itemContainerComponent: 'FieldArrayItemContainer',
          model: 'series',
          label: 'Séries',
          styleClasses: [
            'label-on-top',
          ],
          items: {
            type: 'object',
            schema: {
              fields: [{
                type: 'iconboxes',
                model: 'type',
                label: 'Chart type',
                values: [{
                  value: 'bar',
                  icon: 'chart-bar'
                }, {
                  value: 'pie',
                  icon: 'chart-pie'
                }, {
                  value: 'line',
                  icon: 'chart-line'
                }]
              },{
                type: 'collection',
                model: 'collection',
                label: 'Collection'
              }, {
                label: 'Type des éléments',
                model: 'schema',
                type: 'document',
                labelField: 'name',
                relation: {
                  type: 'hasOne',
                  collection: 'schemas',
                },
                bucket: 'schema',
                onlyFieldAsString: 'name',
                styleClasses: [
                  'half-width',
                ],
              }, {
                model: 'property',
                type: 'schemapropertyselector',
                label: 'Propriété à afficher',
                schemaName: (m) => m.schema,
                styleClasses: [
                  'half-width',
                ],
              }, {
                model: 'color',
                type: 'color',
                label: 'Couleur',
                styleClasses: [
                  'half-width',
                ],
              }]
            }
          }
        }]
      },
      chartOptions: this.computeChart(),
    };
  },
  computed: {
    editLocked () {
      return this.$parent.$parent.editLocked;
    },
    panelConfig: {
      get () {
        if (this.node.attrs.panelConfig) {
          try {
            return JSON.parse(this.node.attrs.panelConfig);
          } catch (e) {
            return {};
          }
        }
      },
      set (v) {
        this.updateAttributes({
          panelConfig: JSON.stringify(v)
        });
      }
    }
  },
  watch: {
    modalOpened () {
      if (this.modalOpened) {
        this.panelConfigVolatile = deepClone(this.panelConfig);
      }
    }
  },
  methods: {
    save () {
      this.panelConfig = { ...this.panelConfigVolatile };
      this.modalOpened = false;
      this.loadData();
    },
    async loadData() {
      this.loaded = false;
      this.chartOptions.series = [];
      if (this.panelConfig.visualisation_type === 'one_document_per_serie') {
        const destinationBucket = `chart-${this.panelConfig.collection}-data`;
        //todo better async handling
        await this.$store.dispatch(`abstractElements/fetchObjects`, {
          collection: this.panelConfig.collection,
          destinationBucket,
        });
        const objs = this.$store.state.abstractElements.objects[destinationBucket].objects;
        for (let obj of objs) {
          const computedSerie = {
          type: 'line',
          name: obj[this.panelConfig.name_key],
          data: [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }};
          for (let key of this.panelConfig.keys) {
            computedSerie.data.push(obj[key]);
          }
          this.chartOptions.series.push(computedSerie);
        }
        this.chartOptions.xAxis = {
          data: this.panelConfig.keys,
          axisLabel: {
             interval: 0,
             rotate: 30 //If the label names are too long you can manage this by rotating the label.
          }
        };
        this.chartOptions.yAxis = {};
      } else {
        if (this.panelConfig.series) {
          for (var i = 0; i < this.panelConfig.series.length; i++) {
            const s = this.panelConfig.series[i];
            const computedSerie = {
              type: s.type || 'bar',
              data: [],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            };
            const destinationBucket = `chart-${s.collection}-${i}`;
            //todo better async handling
            await this.$store.dispatch(`abstractElements/fetchObjects`, {
              collection: s.collection,
              destinationBucket,
            });

            const res = {};
            const objs = this.$store.state.abstractElements.objects[destinationBucket].objects;
            for (let obj of objs) {
              if (res[obj[s.property]] === undefined) {
                res[obj[s.property]] = 1;
              } else {
                res[obj[s.property]] ++;
              }
            }
            if (this.panelConfig.type === 'bar' || this.panelConfig.type === 'line') {
              computedSerie.data = Object.values(res);
              this.chartOptions.xAxis = {
                data: Object.keys(res),
                axisLabel: {
                   interval: 0,
                   rotate: 30 //If the label names are too long you can manage this by rotating the label.
                }
              };
              this.chartOptions.series.push(computedSerie);
              this.chartOptions.yAxis = {};
            } else if (this.panelConfig.type === 'pie') {
              this.chartOptions.series[0].data = Object.keys(res).map(k => ({ value: res[k], name: k }));
              delete this.chartOptions.xAxis;
              delete this.chartOptions.yAxis;
              this.chartOptions.legend = {
                orient: 'vertical',
                left: 'left'
              };
            }
          }
        }
      }
      this.loaded = true;
    },
    computeChart () {
      return {
        tooltip: {
          trigger: "item",
          formatter: "{a}<br/>{b} : {c}"
        },
        series: []
      }
    }
  },
}
</script>

<style scoped>
.edit-modal >>> .modal-card-body {
  height: 75vh;
  display: flex;
  flex-direction:  column;
}
.edit-modal >>> h2::before {
  display: none;
}
.edit-modal >>> h2 {
  font-size: 200%;
  padding-bottom: 30px;
}
.chart {
  position: relative;
  height: 400px;
  border: 1px solid white;
}
.chart:hover {
  border: 1px solid #ccc;
}
.chart-component {
  height: 400px;
}
.chart-type-setup {
  display: flex;
  font-size: 200%;
}
.chart-type-setup > h3 {
  display: block;
}
.chart-type-setup > a {
  padding:  60px;
  border:  1px solid #ddd;
  margin:  20px;
}
.chart-type-setup > a:hover {
  border:  1px solid #ccc;
}
.edit-button {
  position: absolute;
  padding: 10px;
  top: 18px;
  right: 30px;
  z-index: 1;
  opacity: 0;
  width: 48px;
  height: 48px;
  transition: opacity 0.2s linear;
}
.chart:hover .edit-button {
  opacity: 1;
}
.modal-buttons {
  float: right;
}
.chart >>> .b-tabs.is-vertical {
  flex-wrap: unset;
}
.chart >>> .chart-options-tabs {
  margin-bottom: 0;
  flex-grow: 1;
}
.chart >>> .chart-options-tabs.preview-opened .tab-content{
  max-height: calc(75vh - 507px);
  overflow: auto;
}
.chart >>> .chart-options-tabs > .tabs {
  background: #e4e4e4;
}
.chart >>> .chart-options-tabs > .tabs ul {
  margin-left: 20px;
}
.chart >>> .chart-options-tabs > .tabs ul li a {
  width: 250px;
  padding-left: 20px;
}
.chart >>> .chart-options-tabs > .tabs li:hover a {
  background: #ccc;
}
.chart >>> .chart-options-tabs > .tabs li a {
  border-left: none;
  border-bottom: none;
  border-top: none;
  border-radius: 6px 0 0 6px;
}
.chart >>> .chart-options-tabs > .tabs li.is-active a {
  background: white;
  /*border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;*/
}
.chart >>> .modal-card-foot {
  display: block;
}
.chart >>> .preview-card .card-header-title {
  margin: 0;
  background: whitesmoke;
}
.chart >>> .preview-card .card-header-title:hover {
  background: #e4e4e4;
}
.chart >>> .arrayitem-container fieldset {
  padding: 20px;
}
.chart >>> .card.arrayitem-container > div > div > input[type=text] {
  height: 52px;
  border: none;
  box-shadow:  inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  padding-left: 12px;
  padding-right: 28px;
}

</style>
