import { Node, mergeAttributes, wrappingInputRule } from '@tiptap/core'

export const inputRegex = /^\s*(\[([ |x])\])\s$/

export default Node.create({
  name: 'layoutcolumn',

  addOptions() {
    return {
      nested: false,
      HTMLAttributes: {},
    }
  },

  content() {
    return 'paragraph block*'
  },

  defining: true,

  addAttributes() {
    return {}
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="layoutcolumn"]',
        priority: 51,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes,
        { 'data-type': 'layoutcolumn' },
      ),
      0,
    ]
  },
})
