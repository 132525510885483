<template></template>
<script type="text/javascript">
export default {
  data () {
    return {
      testFunction: () => {
        return 'this is the result from the test function';
      },
      currentUser: this.$store.state.login.user,
      collections: {
        panels: {
          everything: () => { return 'TODO everything'; },
          findBy: () => { return 'TODO findBy'; }
        },
        schemas: {
          everything: () => { return 'TODO everything'; },
          findBy: () => { return 'TODO findBy'; }
        }
      }
    }
  }
}
</script>
