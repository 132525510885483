<template>
  <node-view-wrapper class="property">
    <b-tooltip>
      <template slot="content">
        <i class="mdi mdi-key"/>&nbsp;
        {{node.attrs.id}}
      </template>
      <span class="tag">
        {{getProp($parent.$parent.$refs.PropertyListContext, node.attrs.id)}}
      </span>
    </b-tooltip>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';
import getProp from '@/core/utils/getProp';

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  methods: {
    getProp(element, prop) {
      return getProp(element, prop);
    },
  },
}
</script>

<style>
.property {
  display: inline-block;
  font-weight: bold;
  border-radius: 0.5rem;
}
.property .tag {
  font-size: 100%;
}

</style>
