<template>
  <div>
    <portal-target name="portalComplexField" @change="isPortalActive = $event" />
    <div v-show="!isPortalActive">
      <slot />
      <vue-form-generator
        ref="form"
        :schema="schema"
        :model="model"
        tag="div"
        @model-updated="$emit('model-updated', $event)"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    schema: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      isPortalActive: false,
    };
  },
}
</script>
