import { Node, mergeAttributes } from '@tiptap/core';

export default Node.create({
  name: 'layout',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  group: 'block',

  content: 'layoutcolumn+',

  parseHTML() {
    return [
      { tag: 'div[data-type="layout"]' },

    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { 'data-type': 'layoutwrapper' }), ['div', { 'data-type': 'layout' }, 0], ['button', { 'data-type': 'layout-add-column-button' }, '+']]
  },

  addCommands() {
    return {
      insertLayout: () => ({ chain }) => {
        return chain().insertContent(`<div data-type="layout"><div data-type="layout-column">col 1</div><div data-type="layout-column">col 2</div></div>`).run();
      },
    }
  },

})
