<template>
  <node-view-wrapper>
    <div class="file-preview">
      <a v-if="formModel && formModel.url"  :href="formModel.url" target="_blank">
        <i class="mdi mdi-link" /> {{formModel.url}}
      </a>
      <a v-else v-if="!formModel.url" @click="modalOpened = true">
        <i class="mdi mdi-upload" />
        Insérer une url
      </a>
    </div>
    <Modal :active="modalOpened" @close="modalOpened = false">
      <Form :schema="schema" :model="formModel">
      </Form>
      <a class="button" @click="updateModel">
        <i class="mdi mdi-check" />
        Appliquer
      </a>
    </Modal>
  </node-view-wrapper>
</template>
<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';
import Modal from '@/components/modals/Modal';
import Form from '@/components/Form';

export default {
  components: {
    NodeViewWrapper,
    Modal,
    Form,
  },

  props: nodeViewProps,
  tiptapProps: {
    url: {
      default: '',
    },
  },
  data () {
    return {
      schema: {
        fields: [{
          type: 'text',
          model: 'url'
        }]
      },
      modalOpened: false,
    };
  },
  computed: {
    formModel: {
      get() {
        if(this.node.attrs.url) {
          try {
            return {url: this.node.attrs.url };
          } catch (e) {}
        }
        return {};
      },
    },
  },
  methods: {
    updateModel () {
      this.updateAttributes({
        url: this.formModel.url
      });
      this.modalOpened = false;
    },
  },
}
</script>

<style scoped>
.file-preview {
  color:  black;
}
.file-preview >>> a {
  display: block;
  color: #4a4a4a;
  padding-top: 3px;
  padding-bottom: 3px;
}
.file-preview:hover {
  background: whitesmoke;
}
.file-preview:hover >>> a {
  text-decoration: underline;
}

</style>
