<template>
  <node-view-wrapper>
    <div class="file-preview">
      <a v-if="formModel && formModel.file"  @click="openFile(formModel.file)">
        <i class="mdi mdi-file-document-outline" /> {{formModel.file.originalname}}
      </a>
      <a v-else v-if="!formModel.file" @click="modalOpened = true">
        <i class="mdi mdi-upload" />
        Uploader ou sélectionner un fichier
      </a>
    </div>
    <Modal :active="modalOpened" @close="modalOpened = false">
      <Form :schema="schema" :model="formModel" @model-updated="updateModel">
        Ajouter un fichier
      </Form>
    </Modal>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';
import Modal from '@/components/modals/Modal';
import Form from '@/components/Form';
import axios from 'axios';

export default {
  components: {
    NodeViewWrapper,
    Modal,
    Form,
  },

  props: nodeViewProps,
  tiptapProps: {
    file: {
      default: '',
    },
  },
  data () {
    return {
      schema: {
        fields: [{
          type: 'document',
          relation: {
            collection: 'system_files',
            type: 'hasOne',
          },
          onlyFields: [
            'originalname'
          ],
          search: true,
          labelField: 'originalname',
          schemaName: 'file',
          model: 'file'
        }, {
          type: 'files',
          search: true,
          model: 'fileUpload'
        }]
      },
      modalOpened: false,
    };
  },
  computed: {
    formModel: {
      get() {
        if(this.node.attrs.file) {
          try {
            return {file: JSON.parse(this.node.attrs.file) };
          } catch (e) {}
        }
        return {};
      },
    },
  },
  methods: {
    updateModel () {
      setTimeout(() => {
        let file;
        if (this.formModel.fileUpload && this.formModel.fileUpload.length) {
          file = this.formModel.fileUpload[0];
        } else {
          file = this.formModel.file;
        }
        console.log('updatemodel file', this.formModel, file);
        this.updateAttributes({
          file: JSON.stringify(file)
        });
        this.modalOpened = false;
      }, 100);
    },
    openFile (file) {
      const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:3332';
      axios({
        url: `${baseUrl}/system_files/${file._id}/file`,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Token ${this.$store.state.login.user.token}`,
        },
      }).then((response) => {
        if (response.data && response.data.success === false) {
          this.fileDownloadError();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.originalname);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).catch((e) => {
        this.fileDownloadError();
      });
    },
  },
}
</script>

<style scoped>
.file-preview {
  color:  black;
}
.file-preview >>> a {
  display: block;
  color: #4a4a4a;
  padding-top: 3px;
  padding-bottom: 3px;
}
.file-preview:hover {
  background: whitesmoke;
}
.file-preview:hover >>> a {
  text-decoration: underline;
}

</style>
