<template>
  <span>
    <a @click="modalOpened = true">
      <i class="mdi mdi-link-variant" />
    </a>
    <Modal class="edit-modal" :active="modalOpened" @close="modalOpened = false" >
      <template slot="header">
        Partager la vue
      </template>
      <div style="padding: 20px">
        <input type="text" :value="simpleLink" disabled />
        <hr/>
        <h2>Jetons d'accès</h2>
        <b-collapse class="card">
          <template #trigger="props">
            <div class="card-header" role="button">
              <a class="card-header-icon">
                <i :class="`mdi mdi-${props.open ? 'menu-down' : 'menu-right'}`" />
              </a>
              <p class="card-header-title">
                Générer un nouveau jeton d'accès
              </p>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <vue-form-generator
                ref="form"
                :schema="newTokenSchema"
                :model="newTokenModel"
                tag="div"
              />
              <button class="button is-primary"><i class="mdi mdi-plus" /> Créer un jeton d'accès</button>
            </div>
          </div>
        </b-collapse>
        <div class="card">
          <div class="card-content">
            Généré par <span class="tag"><i class="mdi mdi-account" /> gwenael</span>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            Généré par <span class="tag"><i class="mdi mdi-account" /> gwenael</span>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            Généré par <span class="tag"><i class="mdi mdi-account" /> gwenael</span>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            Généré par <span class="tag"><i class="mdi mdi-account" /> gwenael</span>
          </div>
        </div>
      </div>
    </Modal>
  </span>
</template>
<script>
import Modal from '@/components/modals/Modal';

export default {
  components: {
    Modal,
  },
  data () {
    return {
      modalOpened: false,
      newTokenModel: {},
      newTokenSchema: {
        fields: [{
          type: 'select',
          model: 'expiration',
          label: 'Expiration',
          values: ['1 day', '1 week', '1 month'],
        },{
          type: 'permissions',
          label: 'Permissions',
          model: 'roles',
        }]
      }
    };
  },
  computed: {
    simpleLink () {
      return window.location.href;
    }
  }
};
</script>
