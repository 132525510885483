<template>
  <div>
    <v-chart v-if="hasSeries" class="chart-component" :option="option" />
    <div v-else>
      <v-chart :option="sampleData" class="chart-component" />
      <div class="placeholder-text">
        <span>
          Pas de données à afficher
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { BarChart } from 'echarts/charts';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  GridComponent,
  CanvasRenderer,
  PieChart,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light"
  },
  props: {
    option: {
      type: Object,
      default: undefined,
    }
  },
  data () {
    return {
      sampleData: {
        series: [{
          data:[ 20, 10, 5, 10 ],
          type: 'bar'
        }],
        xAxis: {
          axisLabel: {
            interval: 0,
            rotate: 30
          },
          data: [ 'A faire', 'En cours', 'Terminé', 'Archivé' ]
        },
        yAxis: {}
      }
    }
  },
  computed: {
    hasSeries () {
      if (!this.option || !this.option.series) {
        return false;
      }
      return this.option.series.length > 0;
    }
  }
};
</script>
<style scoped>
.chart-component, .placeholder-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.placeholder-text {
  background: #FFFFFFA0;
  font-size: 140%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
