<template>
  <div class="page">
    <ViewUnlockEditButton class="no-print" v-model="editLocked"/>
    <ShareButton class="no-print" />
    <PageContent :page="page" :editLocked="editLocked" />
  </div>
</template>
<script>
import publishErrorReport from '@/core/utils/publishErrorReport';
import ViewUnlockEditButton from '@/components/ui/ViewUnlockEditButton';
import ShareButton from '@/components/ShareButton';
import Counter from '@/components/vfg/FieldWysiwyg/Counter';
import FilePreview from '@/components/vfg/FieldWysiwyg/FilePreview';
import Vue from 'vue';

import PageContent from './PageContent'

Vue.component('Counter', Counter);
Vue.component('FilePreview', FilePreview);
export default {
  components: {
    ViewUnlockEditButton,
    ShareButton,
    PageContent,
  },
  props: {
    page: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      pageFormSchema: { fields: [{ model: 'template', type: 'wysiwyg', components: ['Counter', 'FilePreview'] }] },
    };
  },
  computed: {
    editLocked: {
      get() {
        return this.$store.state.currentPanel.editLocked;
      },
      set(v) {
        this.$store.commit('currentPanel/setEditLocked', v);
      }
    },
  },
  methods: {
  }
};
</script>
<style scoped>
.page >>> .editor {
  width: 100%;
  background: white;
  box-shadow: none;
}
.page >>> .ProseMirror {
  outline: none;
}
</style>
