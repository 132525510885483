import { Node, mergeAttributes } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';


export default {
  make(name, wysiwygComponent) {
    const component = wysiwygComponent.$options.components[name];
    const extensionsSurcharge = component.tiptapExtension ? component.tiptapExtension : {};
    const attributes = component.tiptapProps ? component.tiptapProps : {};
    const node = {
      name,
      group: 'block',
      atom: true,
      selectable: false,

      addCommands() {
        return {
          [`wysiwygAdd_${name}`]: () => ({ chain }) => {
            return chain().insertContent(`<${name}/>`).run();
          },
        }
      },

      addAttributes() {
        return attributes
      },

      parseHTML() {
        return [
          {
            tag: name,
          },
        ]
      },

      renderHTML({ HTMLAttributes }) {
        return [name, mergeAttributes(HTMLAttributes)];
      },

      addNodeView() {
        return VueNodeViewRenderer(component);
      },
      ...extensionsSurcharge
    };
    return Node.create(node)
  }
};
