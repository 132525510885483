<template>
  <div class="editor">
    <PropertyListContext ref="PropertyListContext" />
    <div id="floatingmenu">
      <span class="field has-addons" v-if="!editLocked">
        <p class="control components-dropdown">
          <b-dropdown aria-role="list">
            <template #trigger="{ active }">
              <button class="button is-small add-button">
                <i class="mdi mdi-plus mdi-24px" />
              </button>
            </template>
            <b-dropdown-item @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
              H2
            </b-dropdown-item>
            <b-dropdown-item @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
              H3
            </b-dropdown-item>
            <b-dropdown-item @click="editor.commands.toggleBlockquote()">
              Blockquote
            </b-dropdown-item>
            <b-dropdown-item @click="editor.commands.setHorizontalRule()">
              Horizontal rule
            </b-dropdown-item>
            <b-dropdown-item @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true, resizable: true }).run()">
              insertTable
            </b-dropdown-item>
            <b-dropdown-item @click="runCommand('insertLayout')">
              Colonnes
            </b-dropdown-item>
            <b-dropdown-item @click="editor.chain().focus().toggleTaskList().run()">
              Task list
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" v-for="c in attachedComponents" :key="c" @click="runCommand(`wysiwygAdd_${c}`)">
              {{c}}
            </b-dropdown-item>
          </b-dropdown>
        </p>
      </span>
    </div>
    <bubble-menu
      class="bubble-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
      v-if="editor"
    >
      <span class="field has-addons" v-if="!editLocked">
        <p class="control">
          <a class="button is-small" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
            Bold
          </a>
        </p>
        <p class="control">
          <a class="button is-small" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
            Italic
          </a>
        </p>
        <p class="control">
          <a class="button is-small" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
            Strike
          </a>
        </p>
      </span>
    </bubble-menu>

    <floating-menu
      class="floating-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
      v-if="editor"
    >

    </floating-menu>
    <editor-content :editor="editor" />
  </div>
</template>
<script>
import tippy from 'tippy.js';
import ComponentExtension from './extensions/ComponentExtension';
import Counter from './extensions/Counter';
import FilePreview from './extensions/FilePreview';
import SimpleImage from './extensions/Image';
//import PieExtension from './extensions/PieExtension';
//import WidgetExtension from './extensions/WidgetExtension';
import PropertyExtension from './extensions/PropertyExtension';
import PropertyList from './extensions/PropertyList.vue';
import PropertyListContext from './extensions/PropertyListContext.vue';
import Widget from './extensions/Widget';
import Chart from './extensions/Chart';
import Link from './extensions/Link';
import LayoutExtension from './extensions/LayoutExtension';
import LayoutColumnExtension from './extensions/LayoutColumnExtension';
import Placeholder from '@tiptap/extension-placeholder';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';

import scanComponentVars from '@/core/utils/scanComponentVars';

import {
  Editor,
  EditorContent,
  BubbleMenu,
//  FloatingMenu,
  VueRenderer,
} from '@tiptap/vue-2';
import FloatingMenu from '@tiptap/extension-floating-menu'

import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Blockquote from '@tiptap/extension-blockquote';
import HorizontalRule from '@tiptap/extension-horizontal-rule';

import StarterKit from '@tiptap/starter-kit';
import * as Y from 'yjs'
import { HocuspocusProvider } from '@hocuspocus/provider';

import { IndexeddbPersistence } from 'y-indexeddb';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import Document from '@tiptap/extension-document';
import { mapState } from 'vuex';

const CustomDocument = Document.extend({
  content: 'heading block*',
});

const getRandomElement = list => {
  return list[Math.floor(Math.random() * list.length)]
}

export default {
  props: {
    page: {
      type: Object,
      required: true
    },
    editLocked: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    PropertyListContext,
    EditorContent,
    BubbleMenu,
    FloatingMenu,
    Widget,
    Chart,
    Link,
    FilePreview,
    SimpleImage,
    Counter,
  },
  data () {
    return {
      attachedComponents: ['Counter', 'Widget', 'FilePreview', 'SimpleImage', 'Link', 'Chart'],
      editor: null,
      provider: null,
      indexdb: null,
      room: this.page.page_id,
      currentUser: {
        name: this.getRandomName(),
        color: this.getRandomColor(),
      },
    }
  },

  mounted() {
    const ydoc = new Y.Doc()
    this.provider = new HocuspocusProvider({
      url: process.env.VUE_APP_HOCUSPOCUS_URL || 'wss://localhost:3000',
      name: this.room,
      document: ydoc,
      token: this.user.token,
    })

    //this.provider = new WebsocketProvider('ws://localhost:8084', this.room, ydoc /*, { params: { test: 'TEEEEEEEEEEEEEEEEEEEEE' } }*/)
    this.provider.on('status', event => {
      this.status = event.status
    })

    //this.indexdb = new IndexeddbPersistence(this.room, ydoc);

    const extensions = [
      CustomDocument,
      StarterKit.configure({
        document: false,
        history: false,
      }),
      HorizontalRule.configure(),
      FloatingMenu.configure({
        element: document.getElementById('floatingmenu'),
        shouldShow: ({ editor, view, state, oldState }) => {
          return editor.view.state.selection.empty && editor.view.state.selection.$cursor && editor.view.state.selection.$cursor.parentOffset === 0;
        },
      }),
      LayoutExtension,
      LayoutColumnExtension,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      Blockquote.configure(),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            return '# Quel est le titre du document?';
          }
          return 'Ajouter du contenu';
        },
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      PropertyExtension.configure({
        suggestion: {
          items: ({query}) => {
            return scanComponentVars(this.$refs.PropertyListContext).filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5);
          },
          render: () => {
            let component;
            let popup;
            return {
              onStart: props => {
                component = new VueRenderer(PropertyList, {
                  parent: this,
                  propsData: props,
                  props,
                  editor: props.editor,
                });

                popup = tippy('body', {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => this.$el,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  placement: 'bottom-start',
                });
              },
              onUpdate(props) {
                component.updateProps(props);
                popup[0].setProps({ getReferenceClientRect: props.clientRect });
              },
              onKeyDown(props) {
                if (props.event.key === 'Escape') {
                  popup[0].hide();

                  return true;
                }

                return component.ref?.onKeyDown(props);
              },
              onExit() {
                popup[0].destroy();
                component.destroy();
              },
            }
          }
        },
      }),
      CollaborationCursor.configure({
        provider: this.provider,
        user: this.currentUser,
      }),
      Collaboration.configure({
        document: ydoc,
      }),
    ];
    if (this.attachedComponents) {
      for (let i = 0; i < this.attachedComponents.length; i++) {
        extensions.push(ComponentExtension.make(this.attachedComponents[i], this));
      }
    }
    this.editor = new Editor({
      extensions,
      editable: !this.editLocked,
      onUpdate: () => { this.value = this.editor.getHTML(); },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
    this.provider.destroy();
  },
  watch: {
    editLocked(v) {
      this.editor.setOptions({editable: !v});
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
    })
  },
  methods: {
    runCommand (name) {
      console.log(this.editor.chain().focus());
      this.editor.chain().focus()[name]().run();
    },
    getRandomColor() {
      return getRandomElement([
        '#958DF1',
        '#F98181',
        '#FBBC88',
        '#FAF594',
        '#70CFF8',
        '#94FADB',
        '#B9F18D',
      ]);
    },

    getRandomName() {
      return getRandomElement([
        'Lea Thompson', 'Cyndi Lauper', 'Tom Cruise', 'Madonna', 'Jerry Hall', 'Joan Collins', 'Winona Ryder', 'Christina Applegate', 'Alyssa Milano', 'Molly Ringwald', 'Ally Sheedy', 'Debbie Harry', 'Olivia Newton-John', 'Elton John', 'Michael J. Fox', 'Axl Rose', 'Emilio Estevez', 'Ralph Macchio', 'Rob Lowe', 'Jennifer Grey', 'Mickey Rourke', 'John Cusack', 'Matthew Broderick', 'Justine Bateman', 'Lisa Bonet',
      ]);
    },
  }
};
</script>
<style scoped>
.editor {
  color: #363636;
  border-radius: 4px;
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  background: #f9f9f9;
  line-height: 34px;
  width: 100%;
}
/*
.editor >>> p.control {
  display: inline-block;
}*/
.editor > div {
  width: 100%;
}
.editor >>> .ProseMirror {
  padding:  20px;
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  min-height: calc(100vh - 84px);
}
.editor >>> p {
}
.editor >>> h1 {
  font-weight: bold;
  font-size:  300%;
  color: black;
}
.editor >>> h2 {
  font-weight: bold;
  color: black;
}
.editor >>> h1::before {
  content:  '#';
  color:  #ccc;
  padding-right:  6px;
}
.editor >>> h2::before {
  content:  '##';
  color:  #ccc;
  padding-right:  6px;
}
.floating-menu {
  margin-left:  30px;
}
.add-button {
  padding: 0 4px;
  background: #ddd;
}
.editor >>> .components-dropdown {
  margin-left: -60px;
}
.components-dropdown >>> .dropdown-content {
  margin-left: 40px;
  margin-top: -40px;
}
.editor >>> .table-view th {
  top: 0;
}
.editor >>> .pagination-bar {
  position: static;
}
.editor >>> .ProseMirror table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}
.editor >>> .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.editor >>> .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.editor >>> table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
}
.editor >>> td {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

    }

.editor >>> .tableWrapper table td, .editor >>> .tableWrapper table th {
  min-width: 1em;
  border: 2px solid #ced4da;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}
.editor >>> .collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0D0D0D;
  border-right: 1px solid #0D0D0D;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.editor >>> .collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0D0D0D;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}
.editor >>> .ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}

.editor >>> ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}
.editor >>> ul[data-type="taskList"] p {
  margin: 0;
}

.editor >>> ul[data-type="taskList"] li {
  display: flex;
}
.editor >>> ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

.editor >>> ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
}
.editor >>> div[data-type="layout"] {
  border:  4px solid white;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 60px;
}
.editor >>> div[data-type="layoutwrapper"] {
  position: relative;
  padding-right: 20px;
}

.editor >>> button[data-type="layout-add-column-button"] {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.editor >>> div[data-type="layoutwrapper"]:hover  button[data-type="layout-add-column-button"] {
  opacity: 1;
}
.editor >>> div[data-type="layoutcolumn"] {
  border:  4px solid white;
}
.editor >>> div[data-type="layout"]:hover {
  background: whitesmoke;
}
.editor >>> div[data-type="layout"]:hover div[data-type="layoutcolumn"] {
  border:  4px solid whitesmoke;
  background: white;
}
.editor >>> .pagination-bar {
  width: unset;
}
</style>
